.aside {
    position: fixed;
    left: 0;
    top: 0;
    background: var(--body-color);
    border-right: 1px solid rgba(99, 29, 29, 0.1);
    padding: 1.5rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
}

.nav__list {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    align-items: center;
}

.nav__link {
    font-size: 1.6rem;
    color: var(--title-color);
    font-weight: var(--font-bold);

}

.copyright {
    color: hsl(245, 15%, 65%);
    align-items: center;
}

#logo {
    height: 90px;
    max-height: 90px;
    width: auto;
}

