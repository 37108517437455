/*Google Fonts*/
@import url('https://fonts.googleapis.com/css2?family=Poetsen+One&family=Quicksand:wght@300..700&display=swap');
/*Variables CSS*/
:root {
  /*colors*/
  /*color mode HSL(hue sat lightness)*/
  --first-color: hsl(353, 58%, 48%);
  --title-color: hsl(353, 58%, 46%);
  --text-color: hsl(353, 55%, 37%);
  --body-color: hsl(339, 40%, 89%);
  --container-color: #fff;

  /*font and typography*/
  /*.5rem = 8px | 1rem = 16px*/
  --body-font:  "Quicksand", sans-serif;
  --h1-font-size: 3.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1.1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;

  /*font weight*/
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;

  /*boxshadow*/
  --shadow: 0px 5px 20px 0px rgba(76, 21, 24, 0.1);

  /*border*/
  --border-radius: 20px;

}

  /*responsive typography*/
@media screen and (max-width: 1024px) {
  :root {
    --h1-font-size: 1.75rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;
  }
}

/*BASE*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body, button, input, textarea {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

body {
  background-color: var(--body-color);
  line-height: 1.7;
  color: var(--text-color);
  justify-self: center;
}

h1, h2, h3 {
  color: var(--title-color);
  font-weight: var(--font-semibold);
  line-height: 1.2;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

/*Reusable CSS Classes*/
.container {
  max-width: 1080px;
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
}

.grid {
  display: grid;
}

.section {
  padding-top: 7rem;
  padding-bottom: 1rem;
  justify-content: center;
  align-items: center;

}

.section__title {
  font-size: var(--h1-font-size);
  font-weight: var(--font-bold);
  position: relative;
  margin-bottom: 3.75rem;
 
}


.btn {
  padding: 0.75rem;
  line-height: 1;
  border-radius: 1.875rem;
  box-shadow: 0 0 1px rgb(0 0 0 / 0%);
  border: 1px solid transparent;
  color: #fff;
  display: inline-block;
  background-color: var(--first-color);
  font-weight: var(--font-bold);
}

@keyframes button-push {
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

.btn:hover {
  animation: button-push 0.3s linear 1;
}